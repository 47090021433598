import React from "react"
import { graphql, Link } from "gatsby"
import Page, { Wrapper, Main, Side } from "components/Page"
import Title from "components/Title"
import css from "./style.module.scss"
import { PROJECTS, SOCIAL } from "utils/config"
import Sidebar from "components/Sidebar"
const ProjectItem = ({ title, url, description, image }) => {
  return (
    <a
      target="_blank"
      href={url ? "https://" + url : ""}
      className={css.project}
    >
      <div className={css.image}>
        <img src={"/" + image} />
      </div>
      <div className={css.content}>
        <h3>{title}</h3>
        <p>{description}</p>
        <small>{url && url.split("?ref=")[0]}</small>
      </div>
    </a>
  )
}
export default function Template(props) {
  console.log("data", props)
  const { data } = props

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Page>
      <Title small back="/blog" />
      <Wrapper>
        <Main>
          <div className={css.post}>
            <div className="blog-post">
              <h1>{frontmatter.title}</h1>
              <div className={css.date}>{frontmatter.date}</div>
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className={css.endPost}>
                <Link to="/blog">Return to all articles</Link>
              </div>
            </div>
          </div>
        </Main>
        <Side>
          <Sidebar />
        </Side>
      </Wrapper>
    </Page>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`
